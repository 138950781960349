<template>
  <div
    class="absolute top-14 left-16 z-99 px-4 py-2 bg-white border flex flex-col w-full ml-4 filters-container"
    style="top: 155px; right: 0"
  >
    <div class="flex justify-between w-full">
      <span class="filter_title">{{ $t('monitoring.activeFilters') }}</span>
      <i @click="closeModal" class="el-icon-close pointer close-btn" />
    </div>
    <div
      class="flex mt-2 justify-between mr-2 w-full"
      v-for="(select, index) in filters"
      :key="index"
    >
      <div class="flex justify-between w-full">
        <el-select
          size="small"
          style="height: 32px"
          v-model="select.field"
          @change="handleFilterFieldChange(select, index)"
          :placeholder="$t('monitoring.choose')"
          class="filter-select"
        >
          <el-option
            v-for="(item, key) in filtersList"
            :key="key"
            :label="item.key"
            :value="item.value"
          ></el-option>
        </el-select>
        <template v-if="select.field === 'color'">
          <div class="w-full ml-2" style="flex-grow: 2">
            <el-select
              class="w-full filter-select"
              size="small"
              v-model="select.value"
            >
              <el-option
                v-for="(colorOption, colorIndex) in colorOptions"
                :key="colorIndex"
                :label="colorOption.key"
                :value="colorOption.value"
              >
                <div class="color-option">
                  <span
                    :class="getColorCircle(colorOption)"
                    class="color-circle"
                  ></span>
                  <span>{{ colorOption.key }}</span>
                </div>
              </el-option>
            </el-select>
          </div>
        </template>
        <template v-else-if="select.field === 'form'">
          <div class="w-full ml-2" style="flex-grow: 2">
            <el-select
              class="w-full filter-select"
              size="small"
              v-model="select.value"
            >
              <el-option
                v-for="(formOption, index) in formOptions"
                :key="index"
                :label="formOption.key"
                :value="formOption.value"
              >
              </el-option>
            </el-select>
          </div>
        </template>
        <el-input
          class="ml-2 text-area"
          v-else
          style="flex-grow: 2"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 2 }"
          :placeholder="LocalGeoplaceholder"
          v-model="select.value"
        ></el-input>
      </div>
      <span class="ml-4 pt-1 cursor-pointer" @click.stop="removeFilter(index)">
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 1H0V3H12V1H9L8 0H4L3 1ZM11 4H1V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H9C9.53043 16 10.0391 15.7893 10.4142 15.4142C10.7893 15.0391 11 14.5304 11 14V4Z"
            fill="#446CA7"
          />
        </svg>
      </span>
    </div>
    <p class="filter_title error_text" v-if="isError">
      {{ $t('monitoring.filter.fieldsEmpty') }}
    </p>

    <div class="flex justify-between">
      <el-tooltip
        v-if="isManyFilters"
        :open-delay="700"
        effect="dark"
        :content="$t('monitoring.filterMax')"
        placement="bottom"
        :offset="2"
        :visible-arrow="false"
      >
        <button
          type="button"
          :class="['focus_outline-none', 'filter_buttonDisabled', 'my-4']"
        >
          <span style="pointer-events: none">{{
            $t('monitoring.addFilter')
          }}</span>
        </button>
      </el-tooltip>
      <button
        v-else
        type="button"
        :class="[
          'focus_outline-none',
          'filter_addFilter',
          'cursor-pointer',
          'my-4'
        ]"
        @click="addFilter"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" stroke="#3C5E8F" />
          <path
            d="M7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V5Z"
            fill="#3C5E8F"
          />
          <path
            d="M11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9L5 9C4.44772 9 4 8.55228 4 8C4 7.44772 4.44772 7 5 7L11 7Z"
            fill="#3C5E8F"
          />
        </svg>

        {{ $t('monitoring.addFilter') }}
      </button>

      <span
        class="filter_addFilter accept-btn cursor-pointer my-4"
        @click="search"
        >{{ $t('monitoring.accept') }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'GeoFilter',
  data() {
    return {
      LocalGeoplaceholder: '',
      localizedFiltersList: [
        { key: { en: 'Name', ru: 'Имя', kz: 'Аты' }, value: 'name' },
        { key: { en: 'Color', ru: 'Цвет', kz: 'Түс' }, value: 'color' },
        {
          key: { en: 'Min. Area', ru: 'Мин. площадь', kz: 'Мин. аумақ' },
          value: 'min_area'
        },
        {
          key: { en: 'Max. Area', ru: 'Макс. площадь', kz: 'Макс. аумақ' },
          value: 'max_area'
        },
        { key: { en: 'Form', ru: 'Форма', kz: 'Пішім' }, value: 'form' }
      ],
      localColorOptions: [
        {
          value: '#F56C6C',
          key: { en: 'Red', ru: 'Красный', kz: 'Қызыл' },
          name: 'red'
        },
        {
          value: '#1585D8',
          key: { en: 'Blue', ru: 'Синий', kz: 'Көк' },
          name: 'blue'
        },
        {
          value: '#E6EAEE',
          key: { en: 'Gray', ru: 'Серый', kz: 'Сұр' },
          name: 'gray'
        },
        {
          value: '#E68A2F',
          key: { en: 'Orange', ru: 'Оранжевый', kz: 'Қызғылт' },
          name: 'orange'
        },
        {
          value: '#1BB934',
          key: { en: 'Green', ru: 'Зеленый', kz: 'Жасыл' },
          name: 'green'
        }
      ],

      localizedFormOptions: [
        { value: 'circle', key: { en: 'Circle', ru: 'Круг', kz: 'Даире' } },
        {
          value: 'polygon',
          key: { en: 'Polygon', ru: 'Полигон', kz: 'Полигон' }
        },
        { value: 'line', key: { en: 'Line', ru: 'Линия', kz: 'Сызық' } },
        { value: 'point', key: { en: 'Marker', ru: 'Точка', kz: 'Нүкте' } }
      ],

      isError: false,
      isManyFilters: false,
      filters: [],
      savedFilters: [],
      currentLanguage: ''
    }
  },
  computed: {
    formOptions() {
      return this.localizedFormOptions.map((option) => {
        return {
          value: option.value,
          key: option.key[this.currentLanguage]
        }
      })
    },
    colorOptions() {
      return this.localColorOptions.map((option) => {
        return {
          value: option.value,
          key: option.key[this.currentLanguage],
          name: option.name
        }
      })
    },
    filtersList() {
      return this.localizedFiltersList.map((filter) => {
        return { key: filter.key[this.currentLanguage], value: filter.value }
      })
    }
  },

  methods: {
    getColorCircle(color) {
      return `circle-${color.name}`
    },
    addFilter() {
      if (this.filters.length >= 4) {
        this.isManyFilters = true
      } else {
        this.filters.push({
          field: '',
          value: ''
        })
        this.isManyFilters = this.filters.length >= 4
      }
    },

    removeFilter(index) {
      this.filters.splice(index, 1)
    },
    handleFilterFieldChange(filter) {
      filter.value = ''
    },
    search() {
      if (!this.filters.length) {
        this.clearSavedFilters()
        this.$emit('applyFilters', [])
        return
      }

      const result = JSON.parse(JSON.stringify(this.filters))
      this.isError = false

      result.forEach((filter, index) => {
        if (!filter.field || !filter.value) {
          this.isError = true
          this.errorNumber = index + 1
        }

        switch (filter.field) {
          case 'color':
            const selectedColor = this.colorOptions.find(
              (option) => option.value === filter.value
            )
            if (!selectedColor) {
              this.isError = true
              this.errorNumber = index + 1
            }
            break
          case 'form':
            const selectedForm = this.formOptions.find(
              (option) => option.value === filter.value
            )
            if (!selectedForm) {
              this.isError = true
              this.errorNumber = index + 1
            }
            break

          default:
            break
        }
      })

      if (!this.isError) {
        this.$emit('applyFilters', this.filters)
        this.saveFilters()
      }
    },
    closeModal() {
      this.$emit('close', [])
    },
    saveFilters() {
      localStorage.setItem('savedFilters', JSON.stringify(this.filters))
    },
    clearSavedFilters() {
      localStorage.removeItem('savedFilters')
    },
    loadSavedFilters() {
      const savedFilters = localStorage.getItem('savedFilters')
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters)
      }
    }
  },
  mounted() {
    this.loadSavedFilters()
    this.currentLanguage = this.$i18n.locale()
    this.currentLanguage === 'ru'
      ? (this.LocalGeoplaceholder = 'Геозона 13')
      : this.currentLanguage === 'kz'
      ? (this.LocalGeoplaceholder = 'Геозона 13')
      : (this.LocalGeoplaceholder = 'Geozone 13')
  }
}
</script>
<style>
.color-option {
  display: flex;
  align-items: center;
}

.color-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 6px;
}
.filter-select input,
.text-area textarea {
  background: #f6f7fa;
  border: 1px solid #cdd4dd;
  border-radius: 4px;
}
</style>
<style scoped>
.filters-container {
  z-index: 32;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.error_text {
  color: #f56c6c !important;
  opacity: 1 !important;
}

.filter_title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #828d9b;
  margin-bottom: 12px;
}
.filter_addFilter,
.filter_buttonDisabled {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.filter_addFilter {
  color: #3c5e8f;
}
.accept-btn {
  color: white;
  background-color: #3c5e8f;
  padding: 4px 16px 5px 16px;
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}
.close-btn {
  font-size: 16px;
  color: #446ca7;
  font-weight: 600;
  margin-top: 2px;
  height: 16px;
}
.pointer {
  cursor: pointer;
}
.filter_buttonDisabled {
  color: #96999c;
  cursor: not-allowed;
}
.color-circle {
  width: 20px;
  height: 20px;
  display: block;
}
.circle-red {
  background-color: #f56c6c;
}
.circle-blue {
  background-color: #2f80ed;
}
.circle-green {
  background-color: #1bb934;
}
.circle-gray {
  background-color: #cacdd1;
}
.circle-orange {
  background-color: #e68a2f;
}
</style>
