<template>
  <el-popover width="428" trigger="click" popper-class="popoverToolsGeozones tools-popup" v-model="isClose">
    <button slot="reference" class="transition-colors duration-300 bg-white h-9 w-9 flex items-center justify-center button tools-popup__btn" type="button">
      <GearIcon />
    </button>

    <h2 class="tools-popup__title font-SourceSansPro">
      {{ $t('hide.geozones') }}
    </h2>

    <button class="tools-popup__close" @click="isClose = !isClose">
      <i class="el-icon-close pointer monitoring__closeIcon monitoring__closeIcon-small"></i>
    </button>

    <div class="tools-popup__group">
      <p class="tools-popup__text font-SourceSansPro">
        {{ $t('hide.geozones.text') }}
      </p>

      <skif-switch v-model="toggleGeozones" class="item-button" :is-big="true" @click.native.stop @change="hideGeozones">
        {{ $t('hide') }}
      </skif-switch>
    </div>
    <div class="tools-popup__group mt-4">
      <p class="tools-popup__text font-SourceSansPro">
        {{ $t('geozones.unitArea') }}
      </p>
      <div class="flex">
        <span @click="handleMeasure('m')" class="measure-unit pointer" :class="[currentMeasure === 'm' ? 'unit-active' : '']"> {{ $t('geozones.meter') }}<span class="square">2</span> </span>
        <span @click="handleMeasure('km')" :class="[currentMeasure === 'km' ? 'unit-active' : '']" class="measure-unit pointer"> {{ $t('geozones.km') }} <span class="square">2</span> </span>
        <span @click="handleMeasure('he')" :class="[currentMeasure === 'he' ? 'unit-active' : '']" class="measure-unit pointer">
          {{ $t('geozones.hectar') }}
        </span>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import GearIcon from '@/components/map/tools/images/GearIcon.vue'

export default {
  components: {
    GearIcon
  },

  data: () => ({
    toggleGeozones: '',
    isClose: false,
    currentMeasure: 'm'
  }),

  computed: {
    ...mapState('map', {
      isHiding: state => state.isHidingGeo
    }),
    ...mapGetters('geozones', ['measure'])
  },

  watch: {
    isHiding(val) {
      this.toggleGeozones = val
    }
  },
  methods: {
    ...mapMutations('geozones', ['SET_MEASURE']),
    handleMeasure(newMeasure) {
      this.setCurrentMeasure(newMeasure)
      this.saveMeasure(newMeasure)
      this.$emit('handle-measure', newMeasure)
      this.saveMeasureToLocalStorage(newMeasure)
    },
    setCurrentMeasure(newMeasure) {
      this.currentMeasure = newMeasure
    },
    saveMeasure(newMeasure) {
      this.SET_MEASURE(newMeasure)
    },
    saveMeasureToLocalStorage(newMeasure) {
      localStorage.setItem('measureGeo', JSON.stringify(newMeasure))
    },
    hideGeozones() {
      localStorage.setItem('hidegeozones', JSON.stringify(this.toggleGeozones))
      this.$store.commit('geozones/TOGGLE_GEOZONES', this.toggleGeozones)
    }
  },

  created() {
    this.toggleGeozones = JSON.parse(localStorage.getItem('hidegeozones'))
    this.currentMeasure = this.measure
  }
}
</script>
<style lang="stylus">
.measure-unit {
  cursor: pointer
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 6px 2px 6px 2px

  width: 40px
  height: 24px

  background: #f6f7fa
  border: 1px solid #cdd4dd
  border-radius: 4px 0px 0px 4px
  font-style: normal
  font-weight: 600
  font-size: 12px
  line-height: 120%

  text-align: center

  color: #000000
}
.square {
  font-size: 7px
  margin-bottom: 4px
}
.unit-active {
  background-color: #436c9d
  color: white
}
.popoverToolsGeozones {
  top: 60px !important;
  left: 37px !important;
}
</style>
